import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from './Header';
import Sidebar from './Sidebar';
import { getUserAsync } from 'actions/auth.action';
import { useDispatch } from 'react-redux'

const AppLayout = () => {
    const { token } = useSelector(({ auth }) => auth);
    const navigate = useNavigate();
    const dispatch = useDispatch()

    useEffect(() => {
        document.body.classList.add('has-sidebar');
        return () => document.body.classList.remove('has-sidebar');
    }, [])

    useEffect(() => {
        if (!token) {
            navigate('/auth/signin');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])

    useEffect(() => {
        dispatch(getUserAsync())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="nk-main">
            <div className="nk-wrap">
                <Header />
                <Sidebar />
                <div className="nk-content ">
                    <div className="container-fluid">
                        <div className="nk-content-inner">
                            <div className="nk-content-body">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppLayout;

import React from "react";
import clsx from "clsx";
import { Select } from "antd";

const Footer = ({ pagination_data, page, setPage }) => {
    let links = [], max = pagination_data.last_page, options = [];

    const handleClick = (x) => {
        return (event) => {
            event.preventDefault();
            setPage(x);
        }
    }

    for (let x = 1; x <= max; x++) {
        if (x > 2) break;
        links.push(<li class={clsx("page-item", { active: x === page })}><a class="page-link" href="#" onClick={handleClick(x)}>{x}</a></li>)
    }

    for (let x = 1; x <= max; x++) {
        options.push(<Select.Option value={x}><span>{x}</span></Select.Option>)
    }

    if (max > 4) {
        // show the active link if it is among the collapsed buttons
        if (page > 2 && page <= max - 2) {
            links.push(<li class={clsx("page-item")}><span class="page-link"><em class="icon ni ni-more-h"></em></span></li>)
            links.push(<li class={clsx("page-item active")}><span class="page-link">{page}</span></li>)
            links.push(<li class={clsx("page-item")}><span class="page-link"><em class="icon ni ni-more-h"></em></span></li>)
        } else {
            links.push(<li class={clsx("page-item")}><span class="page-link"><em class="icon ni ni-more-h"></em></span></li>)
        }
        // start from 9 if max = 10
        for (let x = max - 1; x <= max; x++) {
            links.push(<li class={clsx("page-item", { active: x === page })}><a class="page-link" href="#" onClick={handleClick(x)}>{x}</a></li>)
        }
    } else {
        // simply print the rest that are above 2
        for (let x = 3; x <= max; x++) {
            links.push(<li class={clsx("page-item", { active: x === page })}><a class="page-link" href="#" onClick={handleClick(x)}>{x}</a></li>)
        }
    }

    return (
        <div class="card-inner">
            <div class="nk-block-between-md g-3">
                <div class="g">
                    <ul class="pagination justify-content-center justify-content-md-start">
                        <li class={clsx("page-item", { disabled: page === 1 })}><a class="page-link" href="#" onClick={handleClick(page - 1)}>Prev</a></li>
                        {links}
                        <li class={clsx("page-item", { disabled: !(page < max) })}><a class="page-link" href="#" onClick={handleClick(page + 1)}>Next</a></li>
                    </ul>
                </div>
                <div class="g">
                    <div class="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                        <div>Page</div>
                        <div>
                            <Select
                                showSearch
                                optionFilterProp="value"
                                onChange={(value) => setPage(parseInt(value))}
                                filterOption={(input, option) => {
                                    return option.value?.toString().toLowerCase().includes(input.toLowerCase())
                                }}
                                value={page}
                            >
                                {options}
                            </Select>
                        </div>
                        <div>OF {max}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;
import React, { useState } from "react";
import { useSelector } from 'react-redux'
import EditProfile from "./EditProfile";

const PersonalInformation = () => {
    const { user } = useSelector(({ auth }) => auth)
    const [isEditOpen, setIsEditOpen] = useState(false);

    const openModal = () => {
        setIsEditOpen(true);
    }

    return (
        <div class="card-inner card-inner-lg">
            <div class="nk-block-head nk-block-head-lg">
                <div class="nk-block-between">
                    <div class="nk-block-head-content">
                        <h4 class="nk-block-title">Personal Information</h4>
                        <div class="nk-block-des">
                            <p>Basic info, like your name and address, that you use on Nio Platform.</p>
                        </div>
                    </div>
                    <div class="nk-block-head-content align-self-start d-lg-none">
                        <a href="#" class="toggle btn btn-icon btn-trigger mt-n1" data-target="userAside"><em class="icon ni ni-menu-alt-r"></em></a>
                    </div>
                </div>
            </div>
            <div class="nk-block">
                <div class="nk-data data-list">
                    <div class="data-item" onClick={openModal}>
                        <div class="data-col">
                            <span class="data-label">First Name</span>
                            <span class="data-value">{user?.first_name}</span>
                        </div>
                        <div class="data-col data-col-end"><span class="data-more"><em class="icon ni ni-forward-ios"></em></span></div>
                    </div>
                    <div class="data-item" onClick={openModal}>
                        <div class="data-col">
                            <span class="data-label">Last Name</span>
                            <span class="data-value">{user?.last_name}</span>
                        </div>
                        <div class="data-col data-col-end"><span class="data-more"><em class="icon ni ni-forward-ios"></em></span></div>
                    </div>
                    <div class="data-item" onClick={openModal}>
                        <div class="data-col">
                            <span class="data-label">Display Name</span>
                            <span class="data-value">{user?.display_name}</span>
                        </div>
                        <div class="data-col data-col-end"><span class="data-more"><em class="icon ni ni-forward-ios"></em></span></div>
                    </div>
                    <div class="data-item">
                        <div class="data-col">
                            <span class="data-label">Email</span>
                            <span class="data-value">{user?.email}</span>
                        </div>
                        <div class="data-col data-col-end"><span class="data-more disable"><em class="icon ni ni-lock-alt"></em></span></div>
                    </div>
                </div>
            </div>
            <EditProfile isOpen={isEditOpen} setIsOpen={setIsEditOpen} user={user} />
        </div>
    )
}

export default PersonalInformation
import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { sleep } from "utils";
import { showToast } from "utils/toast";

const BulkUploadModal = ({ show, handleClose, uploader }) => {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        maxFiles: 1,
        multiple: false,
        validator: (file) => {
            if (file.name.match(/\.(xls|xlsx)$/)) return null;
            showToast("Invalid file format", "error")
            return {
                code: "invalid-format",
                message: `File format not supported`
            };
        }
    });
    const [uploading, setUploading] = useState(false);
    const [file, setFile] = useState(null);
    const [success, setSuccess] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!uploading) {
            setUploading(false);
            setSuccess(false);
            setFile(null);
        }
    }, [show])

    useEffect(() => {
        const file = acceptedFiles?.[0];
        if (!file) return;
        if (uploader) {
            setFile(acceptedFiles?.[0]);
            const data = new FormData();
            data.append('upload', file);
            setUploading(true);
            sleep(1000).then(() => {
                dispatch(
                    uploader(
                        data,
                        () => {
                            setUploading(false);
                            setSuccess(true);
                            handleClose?.()
                        },
                        () => {
                            setUploading(false);
                            setSuccess(false);
                            setFile(null);
                            handleClose?.()
                        }
                    )
                )
            })
        }
    }, [acceptedFiles])

    if (!show) return null;

    // const file = acceptedFiles?.[0];

    return (
        <Modal show={show} onHide={handleClose}>
            <a onClick={handleClose} href="#" class="close" data-bs-dismiss="modal"><em class="icon ni ni-cross-sm"></em></a>
            <Modal.Body>
                <div class="nk-upload-form">
                    <h5 class="title mb-3">Upload File</h5>
                    <div className="upload-zone small bg-lighter dropzone" style={{ pointerEvents: uploading ? 'none' : undefined }} {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div class="dz-message" data-dz-message>
                            <span class="dz-message-text"><span>Drag and drop</span> file here or <span>browse</span></span>
                        </div>
                    </div>
                </div>
                {
                    file
                        ?
                        <div class="nk-upload-list">
                            <div class="nk-upload-item">
                                <div class="nk-upload-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
                                        <g>
                                            <path d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z" style={{ fill: '#599def' }} />
                                            <path d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z" style={{ fill: '#c2e1ff' }} />
                                            <rect x="27" y="31" width="18" height="2" rx="1" ry="1" style={{ fill: "#ffffff" }} />
                                            <rect x="27" y="36" width="18" height="2" rx="1" ry="1" style={{ fill: "#ffffff" }} />
                                            <rect x="27" y="41" width="18" height="2" rx="1" ry="1" style={{ fill: "#ffffff" }} />
                                            <rect x="27" y="46" width="12" height="2" rx="1" ry="1" style={{ fill: "#ffffff" }} />
                                        </g>
                                    </svg>
                                </div>
                                <div class="nk-upload-info">
                                    <div class="nk-upload-title">
                                        <span
                                            style={{
                                                maxWidth: 270,
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}
                                            class="title"
                                        >{file.name}</span>
                                        <span class="meta">
                                            {
                                                uploading
                                                    ?
                                                    "Uploading..."
                                                    :
                                                    success
                                                        ?
                                                        "Success"
                                                        :
                                                        ""
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div class="nk-upload-item" style={{ height: 66, justifyContent: 'center' }}>
                            No file
                        </div>
                }
                <div class="nk-modal-action justify-end">
                    <ul class="btn-toolbar g-4 align-center">
                        <li><a onClick={handleClose} href="#" class="btn btn-link link-primary">Cancel</a></li>
                        {/* <li><button class="btn btn-primary">Add Files</button></li> */}
                    </ul>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default BulkUploadModal;

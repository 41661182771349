import axios from "axios";
import { getApiToken } from "utils";
import { showToast } from "utils/toast";
import BaseHTTPService, { HttpError, HttpStatus } from "./baseHttpService";
import RouterService from "./routerService";

class ApiService extends BaseHTTPService {
  /**
   * @param {string} baseUrl
   * @param {RouterService} routerService
   */
  constructor(baseUrl, routerService) {
    super(
      axios.create({
        baseURL: baseUrl,
        withCredentials: false,
        headers: {
          "Content-Type": "application/json",
        },
      })
    );
    this.routerService = routerService;
    this.service.interceptors.request.use((config) => {
      const token = getApiToken();

      if (!token) return config;

      if (config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    });
  }

  async request(requestProps) {
    try {
      const response = await super.request(requestProps);
      if (response?.data?.message) {
        showToast(response?.data?.message, response?.data?.status);
      }
      return response;
    } catch (e) {
      const httpError = e;
      if (httpError instanceof HttpError)
        switch (httpError.getStatusCode()) {
          case HttpStatus.Forbidden:
          case HttpStatus.Unauthorized:
            // return this.routerService.navigate("/");
          default:
            const data = httpError.getData();
            console.log(data)
            if (data?.message) {
              console.log(data?.message, data?.status)
              showToast(data?.message, "error")
            }
            throw httpError;
        }
    }
  }
}

export default ApiService;

import { combineReducers } from "redux";
import authReducer from "./authReducer";
import categoryReducer from "./categoryReducer";
import companyReducer from "./companyReducer";
import postReducer from "./postReducer";
import reviewReducer from "./reviewReducer";
import siteReducer from "./siteReducer";
import userReducer from "./userReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  category: categoryReducer,
  post: postReducer,
  site: siteReducer,
  company: companyReducer,
  review: reviewReducer,
  user: userReducer,
});

export default rootReducer;

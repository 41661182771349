import { getTableReviewsAsync, uploadReviewsAsync } from "actions/review.action";
import BulkUploadModal from "components/BulkUploadModal";
import createTable, { getCellText, getColumnText } from "components/Table";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getUserShortName } from "utils";

const ReviewList = () => {
    const { Table } = createTable({
        searchAction: getTableReviewsAsync,
        reducer: 'review',
        reducer_prop: 'paginated_reviews',
        disableSearch: false,
        columns: [
            getColumnText('Title'),
            getColumnText('Review'),
            getColumnText('Rating'),
            getColumnText('Company'),
            getColumnText('Author'),
            // getColumnText('Companies (Count)'),
            // getColumnText('Reviews (Count)'),
            getColumnText('Created At'),
        ],
        extractData: (row) => {
            return [
                getCellText(row.title),
                getCellText(row.review),
                getCellText(row.rating),
                getCellText(row.company.name),
                getCellText(getUserShortName(row.author)),
                // getCellText(row._count.companies),
                // getCellText(row._count.reviews),
                getCellText(moment(new Date(row.created_at)).format("LLL")),
            ]
        }
    });

    const [showModal, setShowModal] = useState(false);

    const uploadErrors = useSelector((store) => store.review.upload_errors)

    const handleClose = (event) => {
        event?.preventDefault?.();
        setShowModal(false);
    }

    const handleShow = (event) => {
        event?.preventDefault?.();
        setShowModal(true);
    }

    return (
        <>
            <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                    <div className="nk-block-head-content">
                        <h3 className="nk-block-title page-title">Reviews</h3>
                    </div>
                    <div className="nk-block-head-content">
                        <Link to={`#`} className="btn btn-icon btn-primary d-md-none">
                            <em className="icon ni ni-upload-cloud"></em>
                        </Link>
                        <Link onClick={handleShow} to={`#`} className="btn btn-primary d-none d-md-inline-flex">
                            <em className="icon ni ni-upload-cloud"></em><span>Upload</span>
                        </Link>
                    </div>
                </div>
            </div>

            {
                uploadErrors.length
                    ?
                    <div className="alert alert-danger alert-icon">
                        <em className="icon ni ni-alert-circle"></em> <strong>Error uploading companies</strong>.
                        <br />
                        {
                            uploadErrors.slice(0, 10).map((e, i) => {
                                return (
                                    <div style={{ marginTop: 7 }}>
                                        {e.error} - Row {e.review.rowNum}
                                    </div>
                                )
                            })
                        }
                        {uploadErrors.length > 10 ? <div style={{ marginTop: 7 }}>And more...</div> : null}
                    </div>
                    :
                    null
            }

            <div className="nk-block">
                <Table />
            </div>

            <BulkUploadModal show={showModal} handleClose={handleClose} uploader={uploadReviewsAsync} />
        </>
    )
}

export default ReviewList;
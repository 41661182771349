import ApiService from "./axios";
import { HttpMethod } from "./baseHttpService";

export default class PostService {
  /**
   * @param {ApiService} apiService
   * @return {PostService}
   */
  constructor(apiService) {
    this.apiService = apiService;
  }

  async addPost(data) {
    const response = await this.apiService.request({
      method: HttpMethod.POST,
      payload: data,
      url: `posts/add-post`,
    });
    return response;
  }

  async updatePost(data) {
    const response = await this.apiService.request({
      method: HttpMethod.POST,
      payload: data,
      url: `posts/update-post`,
    });
    return response;
  }

  async getPost(guid) {
    const response = await this.apiService.request({
      method: HttpMethod.GET,
      params: { guid },
      url: `posts/get`,
    });
    return response;
  }

  async getTablePosts(data) {
    const response = await this.apiService.request({
      method: HttpMethod.GET,
      params: data,
      url: `posts/table`,
    });
    return response;
  }
}

import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom';
import { removeApiToken } from 'utils';

const AuthLayout = () => {
    useEffect(() => {
        document.body.classList.add('pg-auth');
        return () => document.body.classList.remove('pg-auth');
    }, [])

    useEffect(() => {
        removeApiToken();
    }, [])

    return (
        <div className="nk-main">
            <div className="nk-wrap nk-wrap-nosidebar">
                <div className="nk-content ">
                    <Outlet />
                    <div className="nk-footer nk-auth-footer-full">
                        <div className="container wide-lg">
                            <div className="row g-3">
                                <div className="col-12">
                                    <div className="nk-block-content text-center text-lg-left">
                                        <p className="text-soft">&copy; 2022 Dashlite. All Rights Reserved.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthLayout;

import React from "react";

const BulkActions = ({ bulkAction, bulkActions, setBulkAction, selected, data }) => {
    const handleClick = () => {
        const action = bulkActions.filter((e) => e.title === bulkAction)[0]?.action;
        if (!action) return;
        action(data.filter((e, i) => selected.includes(i)))
    }

    return (
        <>
            {
                bulkActions?.length > 0
                    ?
                    <div className="form-inline flex-nowrap gx-3">

                        <div className="form-wrap w-150px">
                            <select className="form-select" value={bulkAction} onChange={(event) => setBulkAction(event.target.value)}>
                                <option value="">Bulk Action</option>
                                {
                                    bulkActions.map(action => {
                                        return <option value={action.title}>{action.title}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="btn-wrap">
                            <span className="d-none d-md-block">
                                <button disabled={bulkAction === "" || selected.length === 0} className="btn btn-dim btn-outline-light" onClick={handleClick}>Apply</button>
                            </span>
                            <span className="d-md-none">
                                <button disabled={bulkAction === "" || selected.length === 0} className="btn btn-dim btn-outline-light btn-icon" onClick={handleClick}>
                                    <em className="icon ni ni-arrow-right"></em>
                                </button>
                            </span>
                        </div>
                    </div>
                    :
                    null
            }
        </>
    )
}

export default BulkActions
import { Modal } from "antd";
import React, { useEffect, useState } from "react";

const EditProfile = ({ isOpen, setIsOpen, user }) => {
    const [state, setState] = useState({
        first_name: "",
        last_name: "",
        display_name: "",
    })

    useEffect(() => {
        if (!user) return
        setState({
            first_name: user.first_name,
            last_name: user.last_name,
            display_name: user.display_name,
        })
    }, [user])

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        })
    }

    return (
        <Modal width={600} title="Update Profile" visible={isOpen} okText="Update Profile" onCancel={() => setIsOpen(false)}>
            {/* <h5 class="title"></h5> */}
            <div class="tab-content">
                <div class="tab-pane active" id="personal">
                    <div class="row gy-4">
                        <div class="col-12">
                            <div class="form-group">
                                <label class="form-label">First Name</label>
                                <input
                                    type="text"
                                    class="form-control form-control-lg"
                                    placeholder="Enter first name"
                                    autoComplete="off"
                                    name="first_name"
                                    value={state.first_name}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label class="form-label">Last Name</label>
                                <input
                                    type="text"
                                    class="form-control form-control-lg"
                                    placeholder="Enter last name"
                                    autoComplete="off"
                                    name="last_name"
                                    value={state.last_name}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label class="form-label">Display Name</label>
                                <input
                                    type="text"
                                    class="form-control form-control-lg"
                                    placeholder="Enter display name"
                                    autoComplete="off"
                                    name="display_name"
                                    value={state.display_name}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default EditProfile;
import { getTableUsersAsync, uploadUsersAsync } from "actions/user.action";
import BulkUploadModal from "components/BulkUploadModal";
import createTable, { getCellText, getColumnText } from "components/Table";
import moment from "moment";
import { useState } from "react";
import { Link } from "react-router-dom";

const UserList = () => {
    const { Table } = createTable({
        searchAction: getTableUsersAsync,
        reducer: 'user',
        reducer_prop: 'paginated_users',
        disableSearch: false,
        columns: [
            getColumnText('First Name'),
            getColumnText('Last Name'),
            getColumnText('Email'),
            getColumnText('Type'),
            getColumnText('Created At'),
        ],
        extractData: (row) => {
            return [
                getCellText(row.first_name),
                getCellText(row.last_name),
                getCellText(row.email),
                getCellText(row.user_type),
                getCellText(moment(new Date(row.created_at)).format("LLL")),
            ]
        }
    });

    const [showModal, setShowModal] = useState(false);

    const handleClose = (event) => {
        event?.preventDefault?.();
        setShowModal(false);
    }

    const handleShow = (event) => {
        event?.preventDefault?.();
        setShowModal(true);
    }

    return (
        <>
            <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                    <div className="nk-block-head-content">
                        <h3 className="nk-block-title page-title">Users</h3>
                    </div>
                    <div className="nk-block-head-content">
                        <Link to={`#`} className="btn btn-icon btn-primary d-md-none">
                            <em className="icon ni ni-upload-cloud"></em>
                        </Link>
                        <Link onClick={handleShow} to={`#`} className="btn btn-primary d-none d-md-inline-flex">
                            <em className="icon ni ni-upload-cloud"></em><span>Upload</span>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="nk-block">
                <Table />
            </div>

            <BulkUploadModal show={showModal} handleClose={handleClose} uploader={uploadUsersAsync} />
        </>
    )
}

export default UserList;
import ApiService from "./axios";
import { HttpMethod } from "./baseHttpService";

export default class WebsiteService {
  /**
   * @param {ApiService} apiService
   * @return {WebsiteService}
   */
  constructor(apiService) {
    this.apiService = apiService;
  }

  async addWebsite(data) {
    const response = await this.apiService.request({
      method: HttpMethod.POST,
      payload: data,
      url: `sites/add-site`,
    });
    return response;
  }

  async getTableWebsites(data) {
    const response = await this.apiService.request({
      method: HttpMethod.GET,
      params: data,
      url: `sites`,
    });
    return response;
  }

  async migrateWebsites(data) {
    const response = await this.apiService.request({
      method: HttpMethod.POST,
      payload: data,
      url: `sites/migrate-sites`,
    });
    return response;
  }
}

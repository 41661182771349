import { getApiToken } from "utils";
import * as types from "../action-types";

const initialState = {
  loading: false,
  redirect_after_login: "",
  user: null,
  token: getApiToken(),
  counts: { companyCount: 0, categoryCount: 0, reviewCount: 0, userCount: 0 }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.LOGIN.REQUEST:
    case types.GET_USER.REQUEST:
    case types.LOGOUT.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.LOGIN.SUCCESS:
      return {
        ...state,
        loading: false,
        token: payload.token?.token,
        user: payload?.user,
      };
    case types.LOGOUT.SUCCESS:
      return {
        ...state,
        loading: false,
        token: null,
        user: null,
      };
    case types.GET_USER.SUCCESS:
      return {
        ...state,
        loading: false,
        user: payload?.user,
        counts: payload?.counts,
      };
    case types.LOGIN.FAILURE:
    case types.LOGOUT.FAILURE:
    case types.GET_USER.FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

import Dropdown, { DropdownMenu, DropdownToggle } from 'components/DropDown';
import React from 'react'
import { Link } from 'react-router-dom';
import AppName from './AppName';
import { useSelector, useDispatch } from 'react-redux'
import { logoutAsync } from 'actions/auth.action';
import { getUserLongName } from 'utils';
// import HeaderMenu from './HeaderMenu';

const Header = () => {
    const { user } = useSelector(({ auth }) => auth);
    const dispatch = useDispatch();

    const full_name = getUserLongName(user);

    return (
        <div className="nk-header nk-header-fixed is-light">
            <div className="container-fluid">
                <div className="nk-header-wrap">
                    <div className="nk-menu-trigger d-xl-none ms-n1">
                        <a href="#" className="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu">
                            <em className="icon ni ni-menu"></em>
                        </a>
                    </div>
                    <AppName />
                    {/* <HeaderMenu /> */}
                    <div className="nk-header-tools">
                        <ul className="nk-quick-nav">
                            <li className="dropdown user-dropdown">
                                <Dropdown rightOffset='extra-padding'>
                                    <DropdownToggle className="dropdown-toggle me-n1">
                                        <div className="user-toggle">
                                            <div className="user-avatar sm">
                                                <em className="icon ni ni-user-alt"></em>
                                            </div>
                                        </div>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu dropdown-menu-md dropdown-menu-end">
                                        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                            <div className="user-card">
                                                <div className="user-avatar">
                                                    <span>{full_name?.split(" ")?.map(e => e.trim().charAt(0))?.join("")}</span>
                                                </div>
                                                <div className="user-info">
                                                    <span className="lead-text">{full_name || "Loading..."}</span>
                                                    <span className="sub-text">{user?.email}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dropdown-inner">
                                            <ul className="link-list">
                                                <li><Link to="/profile-settings"><em className="icon ni ni-user-alt"></em><span>View Profile</span></Link></li>
                                                <li><Link to="/profile-settings?tab=login-activities"><em className="icon ni ni-setting-alt"></em><span>Login Activity</span></Link></li>
                                                <li><Link onClick={(event) => {
                                                    event.stopPropagation()
                                                    event.preventDefault()
                                                }} className="dark-switch" to="#"><em className="icon ni ni-moon"></em><span>Dark Mode</span></Link></li>
                                            </ul>
                                        </div>
                                        <div className="dropdown-inner">
                                            <ul className="link-list">
                                                <li><Link to="#" onClick={(event) => {
                                                    event.preventDefault();
                                                    dispatch(logoutAsync())
                                                }}><em className="icon ni ni-signout"></em><span>Sign out</span></Link></li>
                                            </ul>
                                        </div>
                                    </DropdownMenu>
                                </Dropdown>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;

import React from "react";

const ToolbarRight = ({ children }) => {
    return (
        <div className="card-tools me-n1">
            <ul className="btn-toolbar gx-1">
                {children}
            </ul>
        </div>
    )
}

export default ToolbarRight;
import React from "react";
import { Link, useParams } from "react-router-dom";

const Site404 = () => {
    const { domain } = useParams();

    return (
        <div className="nk-block nk-block-middle wide-md mx-auto">
            <div className="nk-block-content nk-error-ld text-center">
                <img className="nk-error-gfx mx-auto" src="/images/gfx/error-404.svg" alt="" />
                <div className="wide-xs mx-auto">
                    <h3 className="nk-error-title">Oops! Why you’re here?</h3>
                    <p className="nk-error-text">We are very sorry for inconvenience. It looks like you’re try to access a page that either has been deleted or never existed.</p>
                    <Link to={domain ? `/site/${domain}` : '/overview'} className="btn btn-lg btn-primary mt-2">Back To Home</Link>
                </div>
            </div>
        </div>
    )
}

export default Site404;
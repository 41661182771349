import * as types from "action-types";
import { userService } from "services";

export const getTableUsersAsync = (data, successCallback, errorCallback) => {
  return async (dispatch) => {
    dispatch({ type: types.GET_TABLE_USERS.REQUEST });
    try {
      const { data: response, } = await userService.getTableUsers(data);
      dispatch({ type: types.GET_TABLE_USERS.SUCCESS, payload: response?.data });
      successCallback?.();
    } catch (err) {
      dispatch({ type: types.GET_TABLE_USERS.FAILURE, payload: err });
      console.log("act err", err);
      errorCallback?.();
    }
  };
};

export const uploadUsersAsync = (data, successCallback, errorCallback) => {
  return async (dispatch) => {
    dispatch({ type: types.UPLOAD_USERS.REQUEST });
    try {
      const { data: response, } = await userService.uploadUsers(data);
      dispatch({ type: types.UPLOAD_USERS.SUCCESS, payload: response?.data });
      successCallback?.();
    } catch (err) {
      dispatch({ type: types.UPLOAD_USERS.FAILURE, payload: err });
      console.log("act err", err);
      errorCallback?.();
    }
  };
};
import ApiService from "./axios";
import { HttpMethod } from "./baseHttpService";

export default class CategoryService {
  /**
   * @param {ApiService} apiService
   * @return {CategoryService}
   */
  constructor(apiService) {
    this.apiService = apiService;
  }

  async getTableCategories(data) {
    const response = await this.apiService.request({
      method: HttpMethod.GET,
      params: data,
      url: `admin/category`,
    });
    return response;
  }
}

import React from "react";
import { useSelector } from 'react-redux'
import { Link } from "react-router-dom";
// import EditPersonalInformation from "./EditPersonalInformation";
import PersonalInformation from "./PersonalInformation";

const ProfileSettings = () => {
    const { user } = useSelector(({ auth }) => auth)

    return (
        <div class="nk-block">
            <div class="card card-bordered">
                <div class="card-aside-wrap">
                    <PersonalInformation />
                    <div class="card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg" data-toggle-body="true" data-content="userAside" data-toggle-screen="lg" data-toggle-overlay="true">
                        <div class="card-inner-group" data-simplebar>
                            <div class="card-inner">
                                <div class="user-card">
                                    <div class="user-avatar bg-primary">
                                        <span>{user?.nice_name?.split(" ")?.map(e => e.trim().charAt(0))?.join("")}</span>
                                    </div>
                                    <div class="user-info">
                                        <span class="lead-text">{user?.nice_name}</span>
                                        <span class="sub-text">{user?.email}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="card-inner p-0">
                                <ul class="link-list-menu">
                                    <li><Link class="active" to="/profile-settings"><em class="icon ni ni-user-fill-c"></em><span>Personal Infomation</span></Link></li>
                                    <li><Link to="/profile-settings?tab=account-activity"><em class="icon ni ni-activity-round-fill"></em><span>Account Activity</span></Link></li>
                                    <li><Link to="/profile-settings?tab=security-settings"><em class="icon ni ni-lock-alt-fill"></em><span>Security Settings</span></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileSettings;
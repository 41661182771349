import React, { createContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import {
    Toolbar,
    ToolbarLeft,
    ToolbarRight,
    BulkActions,
    TableSearch,
    ToolbarCollapsible,
    ToolbarPaginator,
    TableSeparator,
    TableColumns,
    Footer
} from "./TableComponents";

export const getColumnText = (text, style) => {
    return (
        <div className="nk-tb-col" style={style || {}}>
            <span className="sub-text">{text}</span>
        </div>
    )
}

export const getCellText = (text) => {
    return (
        <div className="nk-tb-col">
            <span
                title={typeof text === "string" ? text : ""}
                style={{
                    overflow: 'hidden',
                    maxWidth: 300,
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    display: 'block'
                }}
            >{text}</span>
        </div>
    )
}

export const getActionWrapper = (actions) => {
    return (
        <div class="nk-tb-col nk-tb-col-tools">
            <ul class="nk-tb-actions gx-1">
                {actions}
            </ul>
        </div>
    )
}

const createTable = (options) => {
    const TableContext = createContext(null);

    const { FilterComponent } = options;

    const Provider = ({ children }) => {
        const dispatch = useDispatch();
        const { searchAction, reducer, reducer_prop, columns, extractData, bulkActions, disableSearch } = options;

        const [searchVisible, setSearchVisible] = useState(false);
        const [searchText, setSearchText] = useState("");
        const [pageLimits, setPageLimits] = useState(options.pageLimits || [10, 20, 50]);
        const [pageLimit, setPageLimit] = useState(options.pageLimit || 20);
        // const [pages, setPages] = useState(1);
        const [page, setPage] = useState(1);
        const [bulkAction, setBulkAction] = useState("");
        const [selected, setSelected] = useState([]);
        const [extraProps, setExtraProps] = useState({});

        const { data, ...pagination_data } = useSelector(reducers => {
            return reducers[reducer][reducer_prop]
        });

        useEffect(() => {
            dispatch(searchAction({
                type: 'paginate',
                page: page,
                per_page: pageLimit,
                search: searchText,
                ...extraProps
            }))
        }, [searchText, pageLimit, extraProps, page])

        return (
            <TableContext.Provider
                value={{
                    searchVisible, setSearchVisible,
                    pageLimits, setPageLimits,
                    pageLimit, setPageLimit,
                    searchText, setSearchText,
                    page, setPage,
                    columns, extractData,
                    data: data,
                    bulkActions,
                    bulkAction, setBulkAction,
                    selected, setSelected,
                    extraProps, setExtraProps,
                    disableSearch,
                    pagination_data
                }}>
                {children}
            </TableContext.Provider>
        );
    };

    const withTable = (Child) => (props) => (
        <TableContext.Consumer>
            {(context) => <Child {...props} {...context} />}
        </TableContext.Consumer>
    );

    const CToolbar = withTable(Toolbar);
    const CToolbarLeft = withTable(ToolbarLeft);
    const CToolbarRight = withTable(ToolbarRight);
    const CBulkActions = withTable(BulkActions);
    const CSearchBox = withTable(TableSearch.SearchBox);
    const CSearchToggle = withTable(TableSearch.SearchToggle);
    const CToolbarCollapsible = withTable(ToolbarCollapsible);
    const CToolbarPaginator = withTable(ToolbarPaginator);
    const CTableSeparator = withTable(TableSeparator);
    const CTableColumns = withTable(TableColumns);
    const CFooter = withTable(Footer);
    const CFilterComponents = FilterComponent ? withTable(FilterComponent) : () => null;

    const Table = () => {
        return (
            <Provider>
                <div className="card card-bordered card-stretch">
                    <div className="card-inner-group">
                        <CToolbar>
                            <div className="card-title-group" style={{ zIndex: 1000 }}>
                                <CToolbarLeft>
                                    <CBulkActions />
                                </CToolbarLeft>
                                <CToolbarRight>
                                    <CSearchToggle />
                                    <CTableSeparator />
                                    <CToolbarCollapsible>
                                        <CToolbarPaginator>

                                        </CToolbarPaginator>
                                    </CToolbarCollapsible>
                                </CToolbarRight>
                            </div>
                            <CSearchBox />
                            <CFilterComponents />
                        </CToolbar>
                        <CTableColumns />
                        <CFooter />
                    </div>
                </div>
            </Provider>
        )
    };

    return {
        Table: Table,
        withTable: withTable,
        Provider: Provider
    }
}

export default createTable;

import { useEditor } from '@craftjs/core';
import { Tooltip } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Checkmark } from 'assets/icons/check.svg';
import { ReactComponent as Customize } from 'assets/icons/customize.svg';
import { ReactComponent as RedoSvg } from 'assets/icons/toolbox/redo.svg';
import { ReactComponent as UndoSvg } from 'assets/icons/toolbox/undo.svg';

const HeaderDiv = styled.div`
    width: 100%;
    height: 45px;
    z-index: 99999;
    position: relative;
    padding: 0px 10px;
    background: #d4d4d4;
    display: flex;
`;

const Btn = styled.a`
    display: flex;
    align-items: center;
    padding: 5px 15px;
    border-radius: 3px;
    color: #fff;
    font-size: 13px;
    svg {
        margin-right: 6px;
        width: 12px;
        height: 12px;
        fill: #fff;
        opacity: 0.9;
    }
`;

const Item = styled.a`
    margin-right: 10px;
    cursor: pointer;
    svg {
        width: 20px;
        height: 20px;
        fill: #707070;
    }
    ${(props) =>
        props.disabled &&
        `
    opacity:0.5;
    cursor: not-allowed;
  `}
`;

export const Header = () => {
    const { enabled, canUndo, canRedo, actions } = useEditor(
        (state, query) => ({
            enabled: state.options.enabled,
            canUndo: query.history.canUndo(),
            canRedo: query.history.canRedo()
        })
    );

    return (
        <HeaderDiv className="header text-white transition w-full">
            <div className="items-center flex w-full px-4 justify-end">
                {enabled && (
                    <div className="flex-1 flex">
                        <Tooltip title="Undo" placement="bottom">
                            <Item
                                disabled={!canUndo}
                                onClick={() => actions.history.undo()}>
                                <UndoSvg />
                            </Item>
                        </Tooltip>
                        <Tooltip title="Redo" placement="bottom">
                            <Item
                                disabled={!canRedo}
                                onClick={() => actions.history.redo()}>
                                <RedoSvg />
                            </Item>
                        </Tooltip>
                    </div>
                )}
                <div className="flex">
                    <Btn
                        className={cx([
                            'transition cursor-pointer',
                            {
                                'bg-green-400': enabled,
                                'bg-primary': !enabled
                            }
                        ])}
                        onClick={() => {
                            actions.setOptions(
                                (options) => (options.enabled = !enabled)
                            );
                        }}>
                        {enabled ? <Checkmark /> : <Customize />}
                        {enabled ? 'Finish Editing' : 'Edit'}
                    </Btn>
                </div>
            </div>
        </HeaderDiv>
    );
};

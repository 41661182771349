import { getTableCategoriesAsync } from "actions/category.action";
import createTable, { getCellText, getColumnText } from "components/Table";
import moment from "moment";

const CategoryList = () => {
    const { Table } = createTable({
        searchAction: getTableCategoriesAsync,
        reducer: 'category',
        reducer_prop: 'paginated_categories',
        disableSearch: false,
        columns: [
            getColumnText('Title'),
            getColumnText('Companies (Count)'),
            getColumnText('Reviews (Count)'),
            getColumnText('Created At'),
        ],
        extractData: (row) => {
            return [
                getCellText(row.title),
                getCellText(row._count.companies),
                getCellText(row._count.reviews),
                getCellText(moment(new Date(row.created_at)).format("LLL")),
            ]
        }
    });

    return (
        <>
            <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                    <div className="nk-block-head-content">
                        <h3 className="nk-block-title page-title">Company Categories</h3>
                    </div>
                </div>
            </div>
            <div className="nk-block">
                <Table />
            </div>
        </>
    )
}

export default CategoryList;
import React from "react"
import Dropdown, { DropdownMenu, DropdownToggle } from 'components/DropDown';

const ToolbarPaginator = ({ pageLimits, pageLimit, setPageLimit }) => {
    return (
        <li>
            <div className="dropdown">
                <Dropdown topOffset={50} rightOffset={20}>
                    <DropdownToggle className="btn btn-trigger btn-icon dropdown-toggle">
                        <em className="icon ni ni-setting"></em>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu dropdown-menu-xs dropdown-menu-end">
                        <ul className="link-check" onClick={(e) => {
                            e.stopPropagation();
                        }}>
                            <li><span>Show</span></li>
                            {
                                pageLimits.map(_limit => {
                                    return (
                                        <li class={_limit === pageLimit ? 'active' : ''}>
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                setPageLimit(_limit);
                                            }}>{_limit}</a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        {/* <ul className="link-check" onClick={(e) => {
                            e.stopPropagation();
                        }}>
                            <li><span>Order</span></li>
                            <li className="active"><a
                                href="#">DESC</a>
                            </li>
                            <li><a href="#">ASC</a></li>
                        </ul> */}
                    </DropdownMenu>
                </Dropdown>
            </div>
        </li>
    )
}

export default ToolbarPaginator;
import * as types from "action-types";
import { identityService } from "services";
import { removeApiToken, setApiToken } from "utils";

export const getUserAsync = (successCallback, errorCallback) => {
  return async (dispatch) => {
    dispatch({ type: types.GET_USER.REQUEST });
    try {
      const { data: { data: response } } = await identityService.getUser();
      console.log(response)
      dispatch({ type: types.GET_USER.SUCCESS, payload: response });
      successCallback?.();
    } catch (err) {
      dispatch({ type: types.GET_USER.FAILURE, payload: err });
      console.log("act err", err);
      errorCallback?.();
    }
  };
};

export const loginAsync = (data, successCallback, errorCallback) => {
  return async (dispatch) => {
    dispatch({ type: types.LOGIN.REQUEST });
    try {
      const { data: response } = await identityService.login(data);
      console.log(response)
      dispatch({ type: types.LOGIN.SUCCESS, payload: response?.data });
      setApiToken(response?.data?.token?.token)
      successCallback?.(response?.data);
    } catch (err) {
      dispatch({ type: types.LOGIN.FAILURE, payload: err });
      console.log("act err", err);
      errorCallback?.();
    }
  };
};

export const logoutAsync = (successCallback, errorCallback) => {
  return async (dispatch) => {
    dispatch({ type: types.LOGOUT.REQUEST });
    try {
      await identityService.logout();
      dispatch({ type: types.LOGOUT.SUCCESS });
      removeApiToken()
      successCallback?.();
    } catch (err) {
      dispatch({ type: types.LOGOUT.FAILURE, payload: err });
      console.log("act err", err);
      errorCallback?.();
    }
  };
};

import cogoToast from "cogo-toast";

export const showToast = (message, type) => {
    switch (type?.toLowerCase()) {
        case "success":
            cogoToast.success(message, { position: "top-right" });
            break;
        case "loading":
            cogoToast.loading(message, { position: "top-right" });
            break;
        case "warn":
            cogoToast.warn(message, { position: "top-right" });
            break;
        case "error":
            cogoToast.error(message, { position: "top-right" });
            break;
        case "info":
        default:
            cogoToast.info(message, { position: "top-right" });
            break;
    }
};

window.showToast = showToast
import ApiService from "./axios";
import AuthService from "./authService";
import WebsiteService from "./websiteService";
import RouterService from "./routerService";
import CategoryService from "./categoryService";
import PostService from "./postService";
import CompanyService from "./companyService";
import ReviewService from "./reviewService";
import UserService from "./userService";

export const routerService = new RouterService();

export const apiService = new ApiService(process.env.REACT_APP_API_URL, routerService);

export const categoryService = new CategoryService(apiService);
export const companyService = new CompanyService(apiService);
export const identityService = new AuthService(apiService);
export const postService = new PostService(apiService);
export const reviewService = new ReviewService(apiService);
export const websiteService = new WebsiteService(apiService);
export const userService = new UserService(apiService);

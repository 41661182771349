import React from "react"

const AppName = () => {
    return (
        <div className="nk-header-app-name">
            <div className="nk-header-app-logo">
                <em className="icon ni ni-dashlite bg-purple-dim"></em>
            </div>
            <div className="nk-header-app-info">
                <span className="sub-text">DashLite</span>
                <span className="lead-text">Dashboard</span>
            </div>
        </div>
    )
}

export default AppName;
import React from "react";

const TableColumns = ({ data, columns, extractData, selected, setSelected, bulkActions }) => {
    const allIsChecked = data.map((e, i) => i).filter(i => selected.includes(i)).length === data.length;

    return (
        <div className="card-inner p-0">
            <div className="nk-tb-list nk-tb-ulist">
                <div className="nk-tb-item nk-tb-head">
                    {
                        bulkActions?.length > 0
                            ?
                            <div className="nk-tb-col nk-tb-col-check">
                                <div
                                    className="custom-control custom-control-sm custom-checkbox notext">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="select-all-migration"
                                        checked={allIsChecked}
                                        onChange={() => {
                                            if (allIsChecked) {
                                                setSelected([])
                                            } else {
                                                setSelected(data.map((e, i) => i))
                                            }
                                        }}
                                    />
                                    <label className="custom-control-label" for="select-all-migration"></label>
                                </div>
                            </div>
                            :
                            null
                    }
                    {columns}
                </div>
                {
                    data.map((e, i) => {
                        return (
                            <div className="nk-tb-item">
                                {
                                    bulkActions?.length > 0
                                        ?
                                        <div className="nk-tb-col nk-tb-col-check">
                                            <div className="custom-control custom-control-sm custom-checkbox notext">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id={`uid${i}`}
                                                    checked={selected.includes(i)}
                                                    onChange={() => {
                                                        const index = selected.indexOf(i);
                                                        if (index === -1) {
                                                            setSelected([...selected, i])
                                                        } else {
                                                            const _clone = [...selected];
                                                            _clone.splice(i, 1)
                                                            setSelected(_clone);
                                                        }
                                                    }}
                                                />
                                                <label className="custom-control-label" htmlFor={`uid${i}`}></label>
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                                {extractData(e)}
                            </div>
                        )
                    })
                }

            </div>
            {
                !data?.length
                    ?
                    <div class="py-2" style={{ fontSize: 14, color: '#8094ae' }}>
                        <div class="text-center w-100">
                            <span>No records found</span>
                        </div>
                    </div>
                    :
                    null
            }
        </div>
    )
}

export default TableColumns;
import ApiService from "./axios";
import { HttpMethod } from "./baseHttpService";

export default class IdentityService {
  /**
   * @param {ApiService} apiService
   * @return {IdentityService}
   */
  constructor(apiService) {
    this.apiService = apiService;
  }

  async login(data) {
    const response = await this.apiService.request({
      method: HttpMethod.POST,
      payload: data,
      url: `admin/signin`,
    });
    return response;
  }

  async logout() {
    const response = await this.apiService.request({
      method: HttpMethod.POST,
      url: `admin/logout`,
      payload: {}
    });
    return response;
  }

  async getUser() {
    const response = await this.apiService.request({
      method: HttpMethod.GET,
      url: `admin`,
    });
    return response;
  }
}

import * as types from "action-types";
import { categoryService } from "services";

export const getTableCategoriesAsync = (data, successCallback, errorCallback) => {
  return async (dispatch) => {
    dispatch({ type: types.GET_TABLE_CATEGORIES.REQUEST });
    try {
      const { data: response, } = await categoryService.getTableCategories(data);
      dispatch({ type: types.GET_TABLE_CATEGORIES.SUCCESS, payload: response?.data });
      successCallback?.();
    } catch (err) {
      dispatch({ type: types.GET_TABLE_CATEGORIES.FAILURE, payload: err });
      console.log("act err", err);
      errorCallback?.();
    }
  };
};
import React, { useEffect, useRef, useState } from "react";

const Dropdown = ({ children, rightOffset = "width", topOffset = "default" }) => {
    const toggleRef = useRef(null);
    const menuRef = useRef(null);
    const [visible, setVisible] = useState(false);
    const [extraMenuStyle, setExtraMenuStyle] = useState(null);

    const Toggle = children?.[0].type;
    const _toggle_props = children?.[0].props;

    const Menu = children?.[1].type;
    const { style: menu_style = {}, ..._menu_props } = children?.[1].props;

    useEffect(() => {
        const _toggle = toggleRef.current;
        const _menu = menuRef.current;
        if (_toggle) {
            if (visible) {
                _toggle.classList.add('show');
            } else {
                _toggle.classList.remove('show');
            }
        }
        if (_menu) {
            if (visible) {
                _menu.classList.add('show');
            } else {
                _menu.classList.remove('show');
            }
        }

        const closeModal = (e) => {
            e.stopPropagation();
            console.log("Clicked");
            setVisible(false);
        }

        // const stopPropagation = (e) => {
        //     e.stopPropagation();
        //     console.log("Stopped");
        // }

        if (visible) {
            // _menu?.addEventListener('click', stopPropagation, false);
            document.body.addEventListener('click', closeModal);
        }
        window.allowDocumentPropagation = visible;
        return () => {
            // _menu?.removeEventListener('click', stopPropagation, false);
            document.body.removeEventListener('click', closeModal)
        }
    }, [visible])

    const toggleVisible = () => {
        if (visible) {
            setVisible(false);
            setExtraMenuStyle(null);
            return;
        }
        const _toggle = toggleRef.current;
        let dropdown = _toggle.parentElement;
        if (!_toggle) return;
        const { height, width } = dropdown.getBoundingClientRect(),
            paddingRight = parseInt(window.getComputedStyle(dropdown, null).getPropertyValue('padding-right'));

        setVisible(true);

        let _rightOffset = 0, _topOffset = 0;

        switch (rightOffset) {
            case "width":
                _rightOffset = -1 * width;
                break;
            case "extra-padding":
                _rightOffset = 0 - paddingRight;
                break;
            default:
                _rightOffset = rightOffset || 0;
                break;
        }

        switch (topOffset) {
            case "default":
                _topOffset = height;
                break;
            case "gapped":
                _topOffset = height + 2;
                break;
            default:
                _topOffset = topOffset || 0;
                break;
        }
        
        setExtraMenuStyle({
            position: 'absolute',
            inset: '0px 0px auto auto',
            margin: 0,
            color: 'black',
            transform: `translate(${_rightOffset}px, ${_topOffset}px)`,
        })
    }

    return (
        <>
            <Toggle {..._toggle_props} ref={toggleRef} onClick={toggleVisible} />
            <Menu
                {..._menu_props}
                style={{
                    ...(menu_style || {}),
                    ...(extraMenuStyle || {}),
                }}
                ref={menuRef}
                setVisible={setVisible}
            />
        </>
    )
}

export const DropdownToggle = React.forwardRef(({ children, onClick, className }, ref) => (
    <a
        href="#"
        className={className}
        ref={ref}
        onClick={(e) => {
            !window.allowDocumentPropagation && e.stopPropagation();
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </a>
));

export const DropdownMenu = React.forwardRef(({ children, style, className, setVisible }, ref) => (
    <div
        className={className}
        ref={ref}
        style={style}
        onClick={(e) => {
            e.stopPropagation();
            setVisible(false)
        }}
    >
        {children}
    </div>
));


export default Dropdown;
import * as types from "action-types";
import { companyService } from "services";

export const getTableCompaniesAsync = (data, successCallback, errorCallback) => {
  return async (dispatch) => {
    dispatch({ type: types.GET_TABLE_COMPANIES.REQUEST });
    try {
      const { data: response, } = await companyService.getTableCompanies(data);
      dispatch({ type: types.GET_TABLE_COMPANIES.SUCCESS, payload: response?.data });
      successCallback?.();
    } catch (err) {
      dispatch({ type: types.GET_TABLE_COMPANIES.FAILURE, payload: err });
      console.log("act err", err);
      errorCallback?.();
    }
  };
};

export const uploadCompaniesAsync = (data, successCallback, errorCallback) => {
  return async (dispatch) => {
    dispatch({ type: types.UPLOAD_COMPANIES.REQUEST });
    try {
      const { data: response, } = await companyService.uploadCompanies(data);
      dispatch({ type: types.UPLOAD_COMPANIES.SUCCESS, payload: response?.data });
      successCallback?.();
    } catch (err) {
      dispatch({ type: types.UPLOAD_COMPANIES.FAILURE, payload: err });
      console.log("act err", err);
      errorCallback?.();
    }
  };
};
import Cookies from 'universal-cookie';

export const getApiToken = () => {
    const cookies = new Cookies();
    return cookies.get('__session');
};

export const setApiToken = (token) => {
    const cookies = new Cookies();
    cookies.set('__session', token, { path: "/", expires: new Date(Date.now() + (1000 * 60 * 60)) });
};

export const removeApiToken = (token) => {
    const cookies = new Cookies();
    cookies.remove('__session', { path: "/" });
};

export const slugify = (str = "") => {
    return str.toLowerCase().replace(/ /g, '-').replace(/[-_]+/g, '-').replace(/[^\w-]+/g, '');
}

export const getUserShortName = (user) => user?.first_name || user?.last_name || ''

export const getUserLongName = (user) => {
    const first_name = user?.first_name || '';
    const last_name = user?.last_name || '';
    return `${first_name} ${last_name}`.trim()
}

export const sleep = (timeout) => new Promise(resolve => setTimeout(resolve, timeout));

import { loginAsync } from "actions/auth.action";
import React, { useState } from "react";
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";

const Login = () => {
    const [state, setState] = useState({
        email: "",
        password: ""
    });

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(
            loginAsync(
                state,
                () => {
                    navigate("/overview");
                }
            )
        )
    }

    return (
        <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
            <div className="brand-logo pb-4 text-center">
                <a href="html/index.html" className="logo-link">
                    <img className="logo-light logo-img logo-img-lg" src="/images/logo.png" srcset="/images/logo2x.png 2x" alt="logo" />
                    <img className="logo-dark logo-img logo-img-lg" src="/images/logo-dark.png" srcset="/images/logo-dark2x.png 2x" alt="logo-dark" />
                </a>
            </div>
            <div className="card card-bordered">
                <div className="card-inner card-inner-lg">
                    <div className="nk-block-head">
                        <div className="nk-block-head-content">
                            <h4 className="nk-block-title text-center mb-3">Sign-In</h4>
                            <div className="nk-block-des">
                                <p>Access the site panel using your email and passcode.</p>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <div className="form-label-group">
                                <label className="form-label" for="default-01">Email</label>
                            </div>
                            <div className="form-control-wrap">
                                <input
                                    type="text"
                                    name="email"
                                    className="form-control form-control-lg"
                                    placeholder="Enter your email address"
                                    value={state.email}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-label-group">
                                <label className="form-label" for="password">Passcode</label>
                            </div>
                            <div className="form-control-wrap">
                                <a href="#" className="form-icon form-icon-right passcode-switch lg" data-target="password">
                                    <em className="passcode-icon icon-show icon ni ni-eye"></em>
                                    <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                                </a>
                                <input
                                    type="password"
                                    className="form-control form-control-lg"
                                    placeholder="Enter your passcode"
                                    name="password"
                                    value={state.password}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <button className="btn btn-lg btn-primary btn-block">Sign in</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login;
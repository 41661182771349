import * as types from "action-types";
import { reviewService } from "services";

export const getTableReviewsAsync = (data, successCallback, errorCallback) => {
  return async (dispatch) => {
    dispatch({ type: types.GET_TABLE_REVIEWS.REQUEST });
    try {
      const { data: response, } = await reviewService.getTableReviews(data);
      dispatch({ type: types.GET_TABLE_REVIEWS.SUCCESS, payload: response?.data });
      successCallback?.();
    } catch (err) {
      dispatch({ type: types.GET_TABLE_REVIEWS.FAILURE, payload: err });
      console.log("act err", err);
      errorCallback?.();
    }
  };
};

export const uploadReviewsAsync = (data, successCallback, errorCallback) => {
  return async (dispatch) => {
    dispatch({ type: types.UPLOAD_REVIEWS.REQUEST });
    try {
      const { data: response, } = await reviewService.uploadReviews(data);
      dispatch({ type: types.UPLOAD_REVIEWS.SUCCESS, payload: response?.data });
      successCallback?.();
    } catch (err) {
      dispatch({ type: types.UPLOAD_REVIEWS.FAILURE, payload: err });
      console.log("act err", err);
      errorCallback?.();
    }
  };
};
import React from "react";

const Toolbar = ({ children, filterComponent }) => {
    return (
        <div className="card-inner position-relative card-tools-toggle">
            {children}
            {
                filterComponent
                    ?
                    <div>
                        {filterComponent}
                    </div>
                    :
                    null
            }
        </div>
    )
}

export default Toolbar;
import React, { useEffect } from "react"
import clsx from "clsx"
import { Link, useLocation } from "react-router-dom";

const sidebar_options = [
    {
        text: "Overview",
        link: "/overview",
        icon: <em className="icon ni ni-speed"></em>
    },
    {
        text: "Categories",
        link: "/categories",
        icon: <em className="icon ni ni-globe"></em>,
    },
    {
        text: "Companies",
        link: "/companies",
        icon: <em className="icon ni ni-exchange"></em>,
    },
    {
        text: "Reviews",
        link: "/reviews",
        icon: <em className="icon ni ni-user-alt"></em>
    },
    {
        text: "Users",
        link: "/users",
        icon: <em className="icon ni ni-user-alt"></em>
    },
]

const Sidebar = () => {
    const location = useLocation();

    useEffect(() => {
        const match = sidebar_options.filter((option) => {
            if (!option.subs?.length) return option.link === location.pathname;
            for (const _sub of option.subs)
                if (_sub.link === location.pathname) return true;
        });
        handleClick(match[0]?.text || "")
    }, [location])

    const handleClick = (active) => {
        const _active = document.querySelector('.nk-menu-parent.active');
        const _new = document.querySelector(`li[data-menu="${active}"]`);

        if (_active?.querySelector('.nk-menu-text')?.innerText?.trim() === active)
            return _active.classList.remove('active')

        _active?.classList?.remove('active')
        _new?.classList?.add('active')
    }

    const NavLink = ({ text, link, icon, subs }) => {
        const has_children = Boolean(subs?.length);
        return (
            <li data-menu={text} class={clsx('nk-menu-item', 'nk-menu-parent', { 'has-sub': has_children, 'current-page': link === location.pathname })}>
                <Link to={link} onClick={(e) => {
                    if (!subs?.length) return;
                    e.preventDefault();
                    handleClick(text);
                }} class={clsx('nk-menu-link', { 'nk-menu-toggle': has_children })}>
                    {
                        icon
                            ?
                            <span className="nk-menu-icon">{icon}</span>
                            :
                            null
                    }
                    <span className="nk-menu-text">{text}</span>
                </Link>
                {
                    has_children
                        ?
                        <ul className="nk-menu-sub">
                            {
                                subs.map((sub, index) => {
                                    let is_active = sub.link === location.pathname;
                                    return (
                                        <li
                                            key={index}
                                            class={clsx('nk-menu-item', { 'active': is_active, 'current-page': is_active })}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <Link to={sub.link} className="nk-menu-link"><span className="nk-menu-text">{sub.text}</span></Link>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        :
                        null
                }
            </li >
        )
    }

    return (
        <div className="nk-sidebar" data-content="sidebarMenu">
            <div className="nk-sidebar-inner" data-simplebar>
                <ul className="nk-menu nk-menu-md">
                    <li className="nk-menu-heading">
                        <h6 className="overline-title text-primary-alt">Profile</h6>
                    </li>
                    {
                        sidebar_options.map((option, index) => {
                            return (<NavLink key={index} {...option} />)
                        })
                    }
                </ul>
            </div>
        </div>
    )
}

export default Sidebar;
import ApiService from "./axios";
import { HttpMethod } from "./baseHttpService";

export default class UserService {
  /**
   * @param {ApiService} apiService
   * @return {UserService}
   */
  constructor(apiService) {
    this.apiService = apiService;
  }

  async getTableUsers(data) {
    const response = await this.apiService.request({
      method: HttpMethod.GET,
      params: data,
      url: `admin/index`,
    });
    return response;
  }

  async uploadUsers(data) {
    const response = await this.apiService.request({
      method: HttpMethod.POST,
      payload: data,
      url: `admin`
    });
    return response;
  }
}

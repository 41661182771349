import React from "react";

const ToolbarCollapsible = ({ children }) => {
    return (
        <li>
            <div className="toggle-wrap">
                <a href="#" className="btn btn-icon btn-trigger toggle"
                    data-target="cardTools">
                    <em className="icon ni ni-menu-right"></em>
                </a>
                <div className="toggle-content" data-content="cardTools">
                    <ul className="btn-toolbar gx-1">
                        <li className="toggle-close">
                            <a href="#" className="btn btn-icon btn-trigger toggle" data-target="cardTools">
                                <em className="icon ni ni-arrow-left"></em>
                            </a>
                        </li>
                        {children}
                    </ul>
                </div>
            </div>
        </li>
    )
}

export default ToolbarCollapsible;
import * as types from "../action-types";

const initialState = {
    loading: false,
    paginated_reviews: {
        total: 0,
        per_page: 0,
        offset: 0,
        to: 0,
        last_page: 0,
        current_page: 0,
        from: 0,
        data: [],
    },
    upload_errors: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case types.GET_TABLE_REVIEWS.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_TABLE_REVIEWS.SUCCESS:
            return {
                ...state,
                loading: false,
                paginated_reviews: payload
            };
        case types.GET_TABLE_REVIEWS.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case types.UPLOAD_REVIEWS.REQUEST:
            return {
                ...state,
                upload_errors: [],
            };
        case types.UPLOAD_REVIEWS.SUCCESS:
            return {
                ...state,
                upload_errors: payload.errors || [],
            };
        default:
            return state;
    }
};

// import SiteLayout from 'layouts/Site';
import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom';
import AppLayout from 'layouts/App';
import AuthLayout from 'layouts/Auth';

import Login from 'pages/Login';
import Site404 from 'pages/Site404';
import ProfileSettings from 'pages/ProfileSettings';
import CategoryList from 'pages/CategoryList';
import EditorPage from 'pages/Editor';
import CompanyList from 'pages/CompanyList';
import ReviewList from 'pages/ReviewList';
import Dashboard from 'pages/Dashboard';
import UserList from 'pages/UserList';

const App = () => {
    return (
        <Routes>
            <Route path="" element={<AppLayout />}>
                <Route index element={<Navigate to="/overview" />} />
                <Route path="overview" element={<Dashboard />} />
                <Route path="categories" element={<CategoryList />} />
                <Route path="companies" element={<CompanyList />} />
                <Route path="reviews" element={<ReviewList />} />
                <Route path="users" element={<UserList />} />
                <Route path="profile-settings" element={<ProfileSettings />} />
                <Route path="*" element={<Site404 />} />
            </Route>
            <Route path="auth" element={<AuthLayout />}>
                <Route path="signin" element={<Login />} />
                <Route path="signout" element={<></>} />
            </Route>
            <Route path="dash" element={<EditorPage />} />
        </Routes>
    )
}

export default App;

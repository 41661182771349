import ApiService from "./axios";
import { HttpMethod } from "./baseHttpService";

export default class ReviewService {
  /**
   * @param {ApiService} apiService
   * @return {ReviewService}
   */
  constructor(apiService) {
    this.apiService = apiService;
  }

  async getTableReviews(data) {
    const response = await this.apiService.request({
      method: HttpMethod.GET,
      params: data,
      url: `admin/review`,
    });
    return response;
  }

  async uploadReviews(data) {
    const response = await this.apiService.request({
      method: HttpMethod.POST,
      payload: data,
      url: `admin/review`
    });
    return response;
  }
}

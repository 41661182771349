import React, { useEffect, useRef } from "react";

const SearchToggle = ({ searchVisible, setSearchVisible, disableSearch }) => {
    const ref = useRef(null);

    useEffect(() => {
        const elem = ref.current;
        if (elem) {
            elem.classList.toggle('active', searchVisible);
        }
    }, [searchVisible])

    if (disableSearch) return null;

    return (
        <li>
            <a href="#" className="btn btn-icon search-toggle toggle-search" data-target="search" ref={ref} onClick={(e) => {
                e.preventDefault();
                setSearchVisible(!searchVisible);
            }}>
                <em className="icon ni ni-search"></em>
            </a>
        </li>
    )
}

const SearchBox = ({ searchVisible, setSearchVisible, searchText, setSearchText }) => {
    const ref = useRef(null);

    useEffect(() => {
        const elem = ref.current;
        if (elem) {
            elem.classList.toggle('active', searchVisible);
            elem.querySelector('a').classList.toggle('active', searchVisible);
        }
    }, [searchVisible])

    return (
        <div className="card-search search-wrap" data-search="search" ref={ref} style={{ zIndex: 1000 }}>
            <div className="card-body">
                <div className="search-content">
                    <a href="#" className="search-back btn btn-icon toggle-search" data-target="search" onClick={(e) => {
                        e.preventDefault();
                        setSearchVisible(!searchVisible);
                    }}>
                        <em className="icon ni ni-arrow-left"></em>
                    </a>
                    <input
                        type="text"
                        className="form-control border-transparent form-focus-none"
                        placeholder="Input Search Query"
                        value={searchText}
                        onChange={(event) => {
                            setSearchText(event.target.value)
                        }}
                    />
                    <button className="search-submit btn btn-icon">
                        <em className="icon ni ni-search"></em>
                    </button>
                </div>
            </div>
        </div>
    )
}

const Search = {
    SearchToggle,
    SearchBox
}

export default Search;
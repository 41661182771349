import * as types from "../action-types";

const initialState = {
    loading: false,
    paginated_posts: {
        total: 0,
        per_page: 0,
        offset: 0,
        to: 0,
        last_page: 0,
        current_page: 0,
        from: 0,
        data: [],
    },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case types.GET_TABLE_POSTS.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_TABLE_POSTS.SUCCESS:
            return {
                ...state,
                loading: false,
                paginated_posts: payload?.posts
            };
        case types.GET_TABLE_POSTS.FAILURE:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};
